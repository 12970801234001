<template>
  <div class="flex-column" v-loading="loading">
    <div class="filter-container">
      <base-search :searchList="$lssjBjglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true'  :noOperation="true" ></base-search>
    </div>
    <div class="bg-white" style="height: 100%;" id="table_box_height">
      <!-- 列表页 -->
      <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange"  @tableColumnClick="tableColumnClick" :key="nowtimer"></basic-table >
    </div>
  </div>
</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import Sticky from '@/components/Sticky'
  export default {
    name: 'bjxx',//报警信息
    components: {
      Sticky
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          queryKey: 'SurfaceAddress',
          queryValue:'',
          abnormalState:'',//异常类型
          processingStatus:'',//处理状态
          timer:[],
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        firstHeaderList:[// 主列表列定义
          {
            key :  "userNumber",
            comment :  "设备编号",
            description :  "设备编号",
          },
          {
            key :  "alarmType",
            comment :  "告警类型",
            description :  "告警类型",
          },
          {
            key :  "handling",
            comment :  "处理情况",
            description :  "处理情况",
          },
          {
            key :  "equipmentAlarm",
            comment :  "告警内容",
            description :  "告警内容",
          },
          {
            key :  "alarmTime",
            comment :  "告警时间",
            description :  "告警时间",
          },
        ],
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
        nowtimer:1,
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
      this.getList()
    },
    mounted() {
      var allHeight = window.innerHeight
      this.tableHeight = allHeight - 45 - 10 - 10 - 40- 100 - 52
      // 计算table高度
      this.$nextTick(()=>{
        var allHeight = window.innerHeight
        this.tableHeight = allHeight - 45 - 10 - 10 - 40- 100 - 52
        // let ele=document.getElementById("table_box_height");
        // this.tableHeight=ele.offsetHeight + 70
        this.nowtimer = new Date().toString()
        this.$forceUpdate()
        window.addEventListener('resize', () => { 
          var allHeight = window.innerHeight
          this.tableHeight = allHeight - 45 - 10 - 10 - 40- 100 - 52
          // let ele=document.getElementById("table_box_height");
          // this.tableHeight=ele.offsetHeight + 70
          this.nowtimer = new Date().toString()
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnDeal'://处理告警
            if(this.chooseList.length <= 0){
              this.$message.error('请选择一条数据进行操作！');
              return;
            }
            this.dealWarning()
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.listQuery.DeviceId=JSON.parse(this.$route.query.row).id
        this.loading = true
        var searchQuery = Object.assign({},this.listQuery)
        if(searchQuery.abnormalState == '' || searchQuery.abnormalState == undefined){searchQuery.abnormalState = '-1'}
        if(searchQuery.processingStatus == '' || searchQuery.processingStatus == undefined){searchQuery.processingStatus = '-1'}
        if(searchQuery.timer.length == 2){
          searchQuery.StartTime = this.$basicFun.dataFormat(searchQuery.timer[0],'yyyy-MM-dd hh:mm');
          searchQuery.EndTime = this.$basicFun.dataFormat(searchQuery.timer[1],'yyyy-MM-dd hh:mm');
        }
        basicDataApi.sjglBjglSearch(searchQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          userNumber:'',
          userName:'',
          installAddress:'',
          surfaceNum:'',
          surfaceName:''
        }
        this.FormDialog = false
      },
      //告警处理
      dealWarning(){
        var ids = []
        this.chooseList.forEach(i=>{
          ids.push(i.id)
        })
        var data={
          ids:ids,
          processingStatus:1
        }
        basicDataApi.gjjldealWarning(data).then(response => {
          if(response.code == 200){
            this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>
